<template>
  <div class="header-container">
    <div class="header container">
      <div class="header-logo"></div>
      <h1 class="header-title">Simulador de hipotecas</h1>
      <div class="colaboration-area">
        En colaboración con
        <div class="colaboration-logo"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped lang="scss">
.header-container {
  border-bottom: 1px solid #f4f4f4;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 10px;

    h1 {
      font-size: 16px;
      margin: 0;
    }

    @media (min-width: 768px) {
      flex-direction: row !important;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>